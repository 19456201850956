// @flow

/**
 * Module dependencies.
 */

import type { ComponentType, Node } from 'react';
import { color, states, units } from '@seegno-labs/react-components/styles';
import { ifProp, switchProp, theme } from 'styled-tools';
import RouterLink from 'components/core/links/router-link';
import styled, { css } from 'styled-components';

/**
 * Export `Button` props.
 */

export type ButtonProps = {|
  as?: Node,
  children: Node,
  className?: string,
  colorTheme?: 'green' | 'grey' | 'indigo' | 'primary' | 'secondary',
  disabled?: boolean,
  fullWidth?: boolean,
  href?: string,
  onClick?: () => void,
  rel?: string,
  size?: 'small' | 'medium' | 'large',
  target?: string,
  to?: string,
  type?: string,
  variant?: 'fill' | 'outline'
|};

/**
 * Create button theme.
 */

const createButtonTheme = (main: string, hover: string, textColor: string) => css`
  border-color: ${color(main)};

  ${ifProp({ variant: 'outline' }, css`
    background-color: transparent;
    color: ${color(main)};

    ${states.interaction`
      border-color: ${color(hover)};
      color: ${color(hover)};
    `}
  `, css`
    background-color: ${color(main)};
    color: ${color(textColor)};

    ${states.interaction`
      background-color: ${color(hover)};
      border-color: ${color(hover)};
    `}
  `)}
`;

/**
 * `Button` styled component.
 */

const Button: ComponentType<ButtonProps> = styled.button.attrs(({
  as,
  colorTheme,
  href,
  size,
  to,
  type
}: ButtonProps) => {
  const element = as || to && RouterLink || href && 'a' || 'button';

  return {
    as: element,
    colorTheme: colorTheme || 'primary',
    size: size || 'large',
    type: type || (element === 'button' ? 'button' : null)
  };
})`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  border: 1px solid;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  font-family: ${theme('typography.fontFamily.saira')};
  justify-content: center;
  letter-spacing: -0.2px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, border-color, color;
  white-space: nowrap;

  ${ifProp('fullWidth', 'width: 100%;')}

  ${ifProp('disabled', css`
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  `)}

  ${states.action`
    outline: none;
    text-decoration: none;
  `}

  ${switchProp('size', {
    large: css`
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      min-height: ${units(6)};
      padding: 10px ${units(4)};
    `,
    medium: css`
      font-size: 16px;
      font-weight: 500;
      line-height: 21px;
      min-height: ${units(5)};
      padding: ${units(0.5)} ${units(2.5)};
    `,
    small: css`
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      min-height: ${units(4)};
      padding: ${units(0.5)} ${units(2.5)};
      text-transform: uppercase;
    `
  })}

  ${switchProp('colorTheme', {
    green: createButtonTheme('green500', 'green300', 'white'),
    grey: createButtonTheme('grey500', 'grey700', 'white'),
    indigo: createButtonTheme('indigo500', 'indigo600', 'white'),
    primary: createButtonTheme('primary', 'blue600', 'white'),
    secondary: createButtonTheme('secondary', 'primary', 'white')
  })}
`;

/**
 * Export `Button` component.
 */

export default Button;
