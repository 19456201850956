// @flow

/**
 * Module dependencies.
 */

import type { ComponentType, Node, StatelessFunctionalComponent } from 'react';
import { Fill } from 'components/core/layout';
import { color, units } from '@seegno-labs/react-components/styles';
import { ifProp, switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `ValidationStatus` type.
 */

export type ValidationStatus = null | 'valid' | 'invalid';

/**
 * `StyledElement` type.
 */

type StyledElementProps = {|
  as?: Node,
  children?: Node,
  className?: string
|};

/**
 * `FormGroupType` type.
 */

type FormGroupProps = {|
  ...StyledElementProps,
  disabled?: boolean,
  validationStatus?: ValidationStatus
|};

/**
 * `StyledElementWithVisibleProp` type.
 */

type StyledElementWithVisibleProp = {|
  ...StyledElementProps,
  visible?: boolean
|};

/**
 * Export `disabledStyle`.
 */

export const disabledStyle: string = `
  cursor: default;
  opacity: 0.55;
  pointer-events: none;
`;

/**
 * Export `FormGroup` styled component.
 */

export const FormGroup: ComponentType<FormGroupProps> = styled.div`
  margin-bottom: ${units(4)};
  position: relative;

  ${ifProp('disabled', disabledStyle)}

  * {
    outline: none;
  }

  ${switchProp('validationStatus', {
    invalid: css`
      --field-label-color: ${color('red500')};
      --field-status-color: ${color('red500')};
      --field-status-height-scale: 1;
    `,
    valid: css`
      --field-label-color: ${color('green500')};
      --field-status-color: ${color('green500')};
      --field-status-height-scale: 1;
    `
  })}

  &:focus-within {
    --field-label-color: ${color('grey500')};
    --field-status-color: ${color('primary')};
  }
`;

/**
 * Export `FormField` styled component.
 */

export const FormField: ComponentType<StyledElementProps> = styled.div`
  background-color: ${color('grey200')};
  border-radius: ${units(1)};
  position: relative;
`;

/**
 * Export `Label` styled component.
 */

export const Label: StatelessFunctionalComponent<{ hasBackground: boolean }> = styled.label`
  border: 0;
  color: var(--field-label-color, ${color('grey400')});
  font-size: 15px;
  left: ${units(2)};
  letter-spacing: -0.2px;
  line-height: 20px;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: ${units(2)};
  text-overflow: ellipsis;
  top: 17px;
  transform: translateY(0);
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, font-size, transform;  /* stylelint-disable-line plugin/no-low-performance-animation-properties */
  white-space: nowrap;
`;

/**
 * Export `Status` styled component.
 */

export const Status: ComponentType<{}> = styled(Fill)`
  border-radius: ${units(1)};
  overflow: hidden;
  pointer-events: none;

  &::after {
    background-color: var(--field-status-color, ${color('primary')});
    bottom: 0;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    right: 0;
    transform: scaleY(var(--field-status-height-scale, 0));
    transform-origin: bottom;
    transition: ${theme('animations.defaultTransition')};
    transition-property: background-color, transform;

    ${ifProp('hasFocus', `
      --field-status-height-scale: 1;
    `)}
  }
`;

/**
 * Export `FormControl` styled component.
 */

export const FormControl: ComponentType<{ ...StyledElementProps }> = styled.input`
  ${theme('typography.styles.p')}

  background-color: transparent;
  border: 0;
  box-shadow: none;
  color: ${color('secondary')};
  display: block;
  height: ${units(7)};
  padding: ${units(2)} ${units(2)} ${units(0.25)};
  width: 100%;

  &[required] {
    background-image: radial-gradient(${color('grey400')} 20%, transparent 25%);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: 1em 1em;
  }

  ${ifProp({ as: 'textarea' }, css`
    height: auto;
    padding-top: ${units(3)};
    resize: vertical;
  `)}

  &::placeholder {
    opacity: 0;
    transition: inherit;
  }

  &:focus {
    ::placeholder {
      opacity: 1;
    }

    + label + div::after {
      --field-status-height-scale: 1;
    }
  }

  :not(:placeholder-shown) + label,
  :focus + label {
    font-size: 12px;
    transform: translateY(-8px);
  }
`;

/**
 * `InputMessage` styled component.
 */

const InputMessage: ComponentType<StyledElementWithVisibleProp> = styled.div`
  ${theme('typography.styles.small')}

  left: ${units(2)};
  line-height: 1em;
  opacity: ${ifProp('visible', 1, 0)};
  overflow: hidden;
  pointer-events: ${ifProp('visible', 'all', 'none')};
  position: absolute;
  right: ${units(2)};
  text-overflow: ellipsis;
  top: calc(100% + 5px);
  transition: opacity ${theme('animations.defaultTransition')};
  white-space: nowrap;
`;

/**
 * Export `HelpMessage` styled component.
 */

export const HelpMessage: ComponentType<StyledElementWithVisibleProp> = styled(InputMessage)`
  color: ${color('grey400')};
`;

/**
 * Export `ErrorMessage` styled component.
 */

export const ErrorMessage: ComponentType<StyledElementWithVisibleProp> = styled(InputMessage)`
  color: ${color('red500')};
`;
