// @flow

/**
 * Module dependencies.
 */

import { color, states } from '@seegno-labs/react-components/styles';
import { ifProp, switchProp, theme } from 'styled-tools';
import RouterLink from 'components/core/links/router-link';
import styled, { css } from 'styled-components';

/**
 * `Link` styled component.
 */

const Link = styled.a.attrs(({ as, colorTheme, size, to }) => ({
  as: as || to && RouterLink || 'a',
  colorTheme: colorTheme || 'white',
  size: size || 'medium'
}))`
  color: inherit;
  text-decoration: none;
  transition: ${theme('animations.defaultTransition')};
  transition-property: color, opacity;

  ${ifProp('upperCase', css`
    text-transform: uppercase;
  `)}

  ${ifProp('underline', css`
    text-decoration: underline;
  `)}

  ${ifProp('disabled', css`
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  `)}

  ${switchProp('colorTheme', {
    grey: css`
      &,
      &:any-link {
        color: ${color('grey700')};
      }

      ${states.interaction`
        color: ${color('primary')};
      `}
    `,
    white: css`
      &,
      &:any-link {
        color: ${color('white')};
      }

      ${states.interaction`
        opacity: 0.75;
      `}
    `
  })}

  ${switchProp('size', {
    large: css`
      font-family: ${theme('typography.fontFamily.saira')};
      font-size: 16px;
      font-weight: 400;
      letter-spacing: -0.2px;
      line-height: 22px;
    `,
    medium: css`
      font-family: ${theme('typography.fontFamily.saira')};
      font-size: 14px;
      font-weight: 400;
      letter-spacing: -0.2px;
      line-height: 20px;
    `,
    small: css`
      ${theme('typography.styles.small')};
    `
  })}
`;

/**
 * Export `Link` component.
 */

export default Link;
