// @flow

/**
 * Module dependencies.
 */

import { get } from 'lodash';
import { media } from '@seegno-labs/react-components/styles';
import { theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Columns regex.
 */

const columnsRegex = /^columns(\w\w)?$/;

/**
 * Get breakpoint.
 */

function getBreakpoint(breakpoints, key) {
  const match = key.match(columnsRegex);

  if (!match) {
    return 'base';
  }

  const [, breakpoint] = match;

  if (!breakpoint) {
    return 'base';
  }

  return breakpoint.toLowerCase();
}

/**
 * Grid template.
 */

const gridTemplate = columns => css`
  grid-template-columns: repeat(${columns}, calc(${100 / columns}% - ${(columns - 1) * 16 / columns}px));

  ${media.min('xs')`
    grid-template-columns: repeat(${columns}, calc(${100 / columns}% - ${(columns - 1) * 24 / columns}px));
  `}
`;

/**
 * Set grid columns.
 */

function setGridColumns(props) {
  const { breakpoints } = props.theme;

  return Object
    .entries(props)
    .filter(([key]) => key.match(columnsRegex))
    .map(([key, columns]) => ({
      breakpoint: getBreakpoint(breakpoints, key),
      columns: parseInt(columns, 10)
    }))
    .sort((first, second) => {
      return get(breakpoints, first.breakpoint) - get(breakpoints, second.breakpoint);
    })
    .map(({ breakpoint, columns }) => {
      if (!breakpoint) {
        return gridTemplate(columns);
      }

      return media.min(breakpoint)`
        ${gridTemplate(columns)}
      `;
    });
}

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  display: grid;
  grid-column-gap: ${theme('grid.gutters.base')}px;

  ${setGridColumns}

  ${media.min('xs')`
    grid-column-gap: ${theme('grid.gutters.xs')}px;
  `}
`;

/**
 * Default props.
 */

Grid.defaultProps = {
  columns: 12
};

/**
 * Export `Grid` component.
 */

export default Grid;
