// @flow

/**
 * Module dependencies.
 */

import { isEmpty } from 'lodash';
import { useFormState } from '@seegno/react-forms';
import Button, { type ButtonProps } from 'slyk-design-system/button';
import Loader from 'components/core/loader';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

/**
 * `SubmitButton` component.
 */

const SubmitButton = (props: ButtonProps): Node => {
  const { children, disabled, ...rest } = props;
  const { fields, isSubmitting } = useFormState();

  return (
    <StyledButton
      disabled={isSubmitting || disabled || !isEmpty(fields.errors)}
      type={'submit'}
      {...rest}
    >
      {isSubmitting ? <Loader /> : children}
    </StyledButton>
  );
};

/**
 * Export `SubmitButton` component.
 */

export default SubmitButton;
