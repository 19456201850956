// @flow

/**
 * Module dependencies.
 */

import { Icon } from '@seegno-labs/react-components/media';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { get } from 'lodash';
import { switchProp, theme } from 'styled-tools';
import React, { type Node, useEffect } from 'react';
import closeIcon from 'assets/svg/close.svg';
import errorIcon from 'assets/svg/error.svg';
import styled, { css } from 'styled-components';
import successIcon from 'assets/svg/success.svg';

/**
 * `Props` type.
 */

type Props = {
  children: Node,
  onClose: () => void,
  type: 'success' | 'error'
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  background-color: ${color('white')};
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  left: 0;
  margin: 0 auto;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: ${units(4)};
  width: 80%;
  z-index: ${theme('zIndex.snackbar')};

  ${media.min('sm')`
    width: ${units(64)};
  `}

  ${media.min('xl')`
    top: ${units(8)};
  `}
`;

/**
 * `IconStatus` styled component.
 */

const IconStatus = styled.div`
  align-items: center;
  color: ${color('white')};
  display: flex;
  padding: ${units(2)};

  ${switchProp('type', {
    error: css`
      background-color: ${color('red500')};
    `,
    success: css`
      background-color: ${color('green500')};
    `
  })}

  ${media.min('sm')`
    padding: ${units(3)};
  `}
`;

/**
 * `MessageWrapper` styled component.
 */

const MessageWrapper = styled(Type.Paragraph)`
  flex: 1;
  padding: ${units(2)};

  ${media.min('sm')`
    padding: ${units(3)};
  `}
`;

/**
 * `CloseWrapper` styled component.
 */

const CloseWrapper = styled.div`
  padding-right: ${units(2)};
  padding-top: ${units(2)};
`;

const CloseButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: 0;
  color: ${color('secondary')};
  cursor: pointer;
  line-height: 0;
  margin: 0;
  outline: none;
  padding: ${units(0.75)};
  transition: color ${theme('animations.defaultTransition')};

  &:hover,
  &:focus {
    color: ${color('primary')};
  }
`;

/**
 * Icons.
 */

const icons = {
  error: errorIcon,
  success: successIcon
};

/**
 * `Message` component.
 */

const Message = (props: Props): Node => {
  const { children, onClose, type } = props;

  useEffect(() => {
    const timeout = setTimeout(() => {
      onClose();
    }, 5000);

    return () => clearTimeout(timeout);
  }, [onClose]);

  return (
    <Wrapper>
      <IconStatus type={type}>
        <Icon
          icon={get(icons, type)}
          size={units(3)}
        />
      </IconStatus>

      <MessageWrapper>
        {children}
      </MessageWrapper>

      <CloseWrapper>
        <CloseButton
          onClick={onClose}
        >
          <Icon
            icon={closeIcon}
            size={units(2)}
          />
        </CloseButton>
      </CloseWrapper>
    </Wrapper>
  );
};

/**
 * Export `Message` component.
 */

export default Message;
