// @flow

/**
 * Module dependencies.
 */

import { Container } from '@seegno-labs/react-components/layout';
import { media, setStyledProps } from '@seegno-labs/react-components/styles';
import { theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `gutterStyles`.
 */

export const gutterStyles = css`
  padding-left: ${theme('grid.gutters.base')}px;
  padding-right: ${theme('grid.gutters.base')}px;

  ${media.min('xs')`
    padding-left: ${theme('grid.gutters.xs')}px;
    padding-right: ${theme('grid.gutters.xs')}px;
  `}
`;

/**
 * `StyledContainer` styled component.
 */

const StyledContainer = styled(Container)`
  ${gutterStyles}

  max-width: ${theme('grid.container')}px;
`;

/**
 * Export `Box` component.
 */

export const Box = styled.div`
  ${props => setStyledProps(props, ['as', 'forwardedComponent', 'style'])}
`;

/**
 * Export layout components.
 */

export { Fill } from '@seegno-labs/react-components/layout';
export { default as Grid } from './grid';
export { StyledContainer as Container };
