// @flow

/**
 * Module dependencies.
 */

import { FormProvider } from '@seegno/react-forms';
import React, { type Node } from 'react';

/**
 * `Props` type.
 */

type Props = {
  children?: Node,
  className?: string,
  initialValues?: Object,
  jsonSchema: Object,
  onSubmit: (values: Object, formActions: Object) => Promise<any>
};

/**
 * `Form` component.
 */

const Form = (props: Props): Node => {
  const { initialValues, jsonSchema, onSubmit, ...rest } = props;

  return (
    <FormProvider
      initialValues={initialValues}
      jsonSchema={jsonSchema}
      onSubmit={onSubmit}
    >
      {({ submit }) => (
        <form
          {...rest}
          onSubmit={submit}
        />
      )}
    </FormProvider>
  );
};

/**
 * Export `Form` component.
 */

export default Form;
